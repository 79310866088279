<!--
  Form used to save, add and edit list item subheaders from a list
-->

<template>
  <div>
    <v-dialog
      v-if="listCurrent"
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('list.form.formSaveSubHeader') }}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="cancelDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="listItemForm"
            v-model="valid"
            v-if="data"
            valid
            @submit.prevent
            @keyup.enter.native="onEnterKeyPress()"
          >
            <!-- eslint-disable vue/no-mutating-props -->
            <v-text-field
              v-model="data.title"
              auto-complete="off"
              :rules="titleRules"
              :label="`${$t('generic.title')}*`"
              required
            />
            <!-- eslint-enable vue/no-mutating-props -->
            <div class="text-end mt-8 mr-n4 mb-n3">
              <v-btn
                class="mr-2"
                @click="cancelDialog()"
              >
                {{ $t('generic.cancel') }}
              </v-btn>

              <v-btn
                color="teal lighten-1"
                class="white--text"
                @click="submitForm()"
                :disabled="disabled"
                :loading="busy"
              >
                {{ $t('generic.save') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { hideDialog } from '@/helpers/dialogHelper';
// import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'ListManagerItemSubHeaderForm',

  props: {
    data: {
      type: Object,
      default() {
        return {
          title: null,
        };
      },
    },
  },

  data() {
    return {
      notRequired: false,
      valid: false,
      dialog: true,
      date: null,
      titleRules: [
        (v) => !!v || this.$t('list.form.updateTitleMandatory'),
      ],
      busy: false,
      disabled: false,
      formLabelWidth: '95px',
      customDateMenu: false,
    };
  },

  computed: {
    ...mapGetters({
      listsActive: 'listsActive',
      listCurrent: 'listCurrent',
    }),

    // computedCustomDate() {
    //   if (this.data.customDocument.date) {
    //     return moment(this.data.customDocument.date).format('DD-MM-YYYY');
    //   }
    //   return null;
    // },
  },

  methods: {
    hideDialog,

    resetFormData() {
      // eslint-disable-next-line vue/no-mutating-props
      this.data = {};
    },

    cancelDialog() {
      if (!this.data.isSubHeader) {
        this.$store.dispatch('getList', this.listCurrent.id);
      }
      this.resetFormData();
      this.hideDialog();
    },

    onEnterKeyPress() {
      this.submitForm();
    },

    async submitForm() {
      if (this.$refs.listItemForm.validate()) {
        this.busy = true;
        this.disabled = true;

        const cleanedLists = this.data.lists.filter((item) => item !== this.listCurrent.id);
        // We add a sourceName because the backend is asking for it
        const params = {
          listItem: this.data,
          copyToLists: cleanedLists,
        };

        await this.$store.dispatch('saveListItem', params);

        if (this.data.isSubHeader) {
          await this.$store.dispatch('getList', this.listCurrent.id);
        }
        this.resetFormData();
        this.hideDialog();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
</style>
