<template>
  <div>
    <v-card
      class="mb-4 list-item"
      :color="data.isSubHeader && !this.$vuetify.theme.dark ? '#F5F5F5' : ''"
      :outlined="!data.isSubHeader"
    >
      <v-card-title class="py-2">
        <v-row>
          <v-col class="d-flex justify-start align-center">
            <v-tooltip
              v-if="!data.isSubHeader"
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-3"
                  fab
                  :outlined="!data.markedForExport"
                  :color="data.markedForExport ? 'teal' : 'grey'"
                  :dark="data.markedForExport"
                  small
                  @click.stop="toggleExport()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('list.selectForExport') }}</span>
            </v-tooltip>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="data.isSubHeader ? showInsertSubHeaderForm(data) : showForm(data)"
                  class="mr-2"
                  icon
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('generic.edit') }}</span>
            </v-tooltip>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click.stop="confirmRemoveListItem()"
                  :loading="removeBusy"
                  :disabled="removeDisabled"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('generic.delete') }}</span>
            </v-tooltip>

            <v-tooltip
              v-if="!data.isSubHeader"
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="grey"
                  @click.stop="showInsertSubHeaderForm()"
                  :loading="removeBusy"
                  :disabled="removeDisabled"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-format-align-top</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('list.addSubheading') }}</span>
            </v-tooltip>

          </v-col>
          <v-col class="d-flex justify-end align-center ma-0 py-0">
            <v-btn
              icon
              @click.stop="moveUp()"
              :disabled="index == 0"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>

            <v-btn
              icon
              @click.stop="moveDown()"
              :disabled="index == totalItems - 1"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p v-if="!data.isSubHeader" class="text-subtitle-1">
          <span class="font-italic">{{ typeName }}</span>
          &mdash; {{ data.title }}
          <span
            v-if="data.duplicate"
            class="yellow black--text accent-2 m-2 px-2 font-weight-bold"
            style="display: inline-block;"
          >
            {{$t('list.duplicate')}}!
          </span>
        </p>
        <p v-else class="text-h5 text-uppercase">
          {{ data.title }}
          <span
            v-if="data.duplicate"
            class="yellow black--text accent-2 m-2 px-2 font-weight-bold"
            style="display: inline-block;"
          >
            {{$t('list.duplicate')}}!
          </span>
        </p>
        <p
          v-if="!data.isSubHeader && date"
        >
          {{ dateAsString(date) }}
        </p>
        <p
          v-if="!data.isSubHeader && !date"
          class="text--disabled"
        >
          {{ $t('list.noDateGiven') }}
        </p>
        <div
          class="custom-description"
          v-if="!data.isSubHeader && (data.description && data.description !== '<p></p>')"
          v-html="data.description"
        >
        </div>
        <p
          v-if="!data.isSubHeader && (!data.description || data.description === '<p></p>')"
          class="text--disabled"
        >
          {{ $t('list.noDescriptionGiven') }}
        </p>
        <p
          v-if="!data.isSubHeader && (url || data.document)"
        >
          {{ $t('generic.source') }}:
          <a
            :href="`${url.startsWith('http') ? `${url}` : `//${url}`}`"
            rel="noopener noreferrer"
            target="_blank"
            class="text--primary text-decoration-none"
          >
            {{ computedUrlToSourceName }}
          </a>
          <span v-if="!data.isSubHeader && data.document && documentExistsPolpo">
            /
            <a
              :href="documentPolpoUrl"
              target="_blank"
              class="text--primary text-decoration-none"
            >
              Open in Polpo
            </a>
          </span>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import useDocumentTypes from '@/composables/documentTypesComposable';
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus';
import { showDialog } from '@/helpers/dialogHelper';
import urlToSourceName from '@/helpers/urlToSourceNameHelper';
import ListManagerItemForm from './ListManagerItemForm.vue';
import ListManagerItemInsertSubHeaderForm from './ListManagerItemInsertSubHeaderForm.vue';

export default {
  name: 'ListManagerItem',

  setup() {
    const {
      documentTypesMixinReady,
      getDocTypeByDocTypeId,
      getLocalizationForDocTypeId,
      findDocumentTypesByLabels,
      modulesMeetDocumentTypeRequirements,
      getPrunedDocumentTypesByModules,
      getAllSupportedEuroparlCodes,
      getAllEuroparlDocumentTypesPerInstitution,
    } = useDocumentTypes();

    return {
      documentTypesMixinReady,
      getDocTypeByDocTypeId,
      getLocalizationForDocTypeId,
      findDocumentTypesByLabels,
      modulesMeetDocumentTypeRequirements,
      getPrunedDocumentTypesByModules,
      getAllSupportedEuroparlCodes,
      getAllEuroparlDocumentTypesPerInstitution,
    };
  },

  props: {
    listId: String,
    index: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    toggleExport: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      showArchived: false,
      referrer: false,
      removeBusy: false,
      removeDisabled: false,
      formData: null,
      formVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      userFilters: 'userFilters',
      userPreferences: 'userPreferences',
    }),

    computedUrlToSourceName() {
      return urlToSourceName(this.url);
    },

    documentPolpoUrl() {
      const { nlGroupsByDocType, euGroupsByDocType, nlLrGroupsByDocType } = this.userFilters;
      let destination;
      if (nlGroupsByDocType[this.data.document.type]) destination = 'nl';
      else if (euGroupsByDocType[this.data.document.type]) destination = 'eu';
      else if (nlLrGroupsByDocType[this.data.document.type]) destination = 'nl-lr';
      else throw new Error(`doctype ${this.data.document.type} not found in any dashboard Grouping`);
      return `${destination}/document/${this.data.document.id}`;
    },

    documentExistsPolpo() {
      const { nlGroupsByDocType, euGroupsByDocType, nlLrGroupsByDocType } = this.userFilters;
      return !!(nlGroupsByDocType[this.data.document.type]
                || euGroupsByDocType[this.data.document.type]
                || nlLrGroupsByDocType[this.data.document.type]);
    },

    url() {
      return this.data.document ? this.data.document.sourceUrl : this.data.customDocument.sourceUrl;
    },
    date() {
      return this.data.document ? this.data.document.date : this.data.customDocument.date;
    },
    typeName() {
      if (!this.documentTypesMixinReady) return undefined;
      return this.data.document
        ? this.getLocalizationForDocTypeId(
          this.data.document.type,
          { locale: this.userPreferences.locale },
        )
        : this.data.customDocument.sourceName;
    },
  },
  methods: {
    confirmRemoveListItem() {
      EventBus.$on('submitConfirmDialog', this.removeListItem);
      this.$store.dispatch('setConfirmDialogHeader', 'Update verwijderen');
      this.$store.dispatch(
        'setConfirmDialogBody',
        this.$t('list.confirmDialog.removeUpdateBody'),
      );
      this.$store.dispatch('setConfirmDialogVisible', true);
    },
    async removeListItem() {
      this.busyRemove = true;
      this.disabled = true;

      await this.$store.dispatch('removeListItem', this.data);
      this.busyRemove = false;
      this.disabled = false;

      this.$gtag.event('list-manager-item-remove', {
        event_category: 'list-manager',
      });

      EventBus.$off('submitConfirmDialog');
    },
    dateAsString(date) {
      return this.$moment(date).format('dddd D MMMM YYYY');
    },
    async moveUp() {
      await this.$store.dispatch('moveListItem', {
        index: this.index,
        direction: -1,
        listId: this.listId,
      });
      this.$gtag.event('list-manager-item-move', {
        event_category: 'list-manager',
        event_label: 'list-manager-item-move-up',
      });
    },
    async moveDown() {
      await this.$store.dispatch('moveListItem', {
        index: this.index,
        direction: 1,
        listId: this.listId,
      });
      this.$gtag.event('list-manager-item-move', {
        event_category: 'list-manager',
        event_label: 'list-manager-item-move-down',
      });
    },
    showForm(data) {
      showDialog(ListManagerItemForm, {
        ...data,
        list: [this.listId], // No longer used??
        lists: [this.listId],
      });

      this.$gtag.event('list-manager-item-edit', {
        event_category: 'list-manager',
      });
    },
    showInsertSubHeaderForm(data) {
      showDialog(ListManagerItemInsertSubHeaderForm, {
        ...data,
        isSubHeader: true,
        atPosition: (this.data.sortOrder + 1),
        list: [this.listId], // No longer used??
        lists: [this.listId],
      });

      this.$gtag.event('list-manager-item-edit', {
        event_category: 'list-manager',
      });
    },
    trackOpenSource(url) {
      this.$gtag.event('list-manager-item-source-external', {
        event_category: 'list-manager',
        event_label: url,
      });
    },
    trackOpenPolpo(url) {
      this.$gtag.event('list-manager-item-source-polpo', {
        event_category: 'list-manager',
        event_label: url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-description {
    p {
      min-height: 1rem;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    margin-bottom: 16px !important;
  }
}
</style>
